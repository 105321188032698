// import './assets/main.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './assets/css/style.css'
import './assets/css/style_new.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Toaster from '@meforma/vue-toaster';
import store from './stores';
import { Access } from "./DIrectives/Access";
// import JsonExcel from "vue-json-excel3";
import { Chart } from 'vue-chartjs';
const app = createApp(App)

// List of Permissions [to set local-Storage for a example]
// localStorage.setItem('permissions', 'Payroll Admin');


app.use(router)
app.use(Chart);

// app.component("downloadExcel", JsonExcel);
app.use(store)
app.use(Toaster, {
    duration: 3000,
    position: 'top-right'
})
app.directive('can', Access)
app.mount('#app')