
import { userStore } from "@/stores/store";
import { subscriptionType } from "@/utills/enum";

const Access = {
  mounted: (el, binding) => {
    var user = userStore?.activeUser ?? JSON.parse(localStorage.getItem("activeUser"));
    var userSub = localStorage.getItem("subscription_type");
    console.log(userSub);
    var userModules = user?.userModuleRole;
    console.log(user);
    if (binding?.value?.module && user?.organization_role != 'OWNER') {
      let hasPermission = userModules?.find(x => x?.modules?.short_code == binding?.value?.module);
      if (hasPermission == null) {
        el.style.display = 'none';
      }
    }else if(binding?.value?.module == 'PAYROLL' && user?.organization_role == 'OWNER'){
      if(userSub == subscriptionType.SoleTrader) {
        el.style.display = 'none';
      }
    }
    if (binding?.value?.element) {
      let hasPermission = findPermission(user?.organization_role, binding?.value?.element);
      if (!hasPermission) {
        el.style.display = 'none';
      }
    }
  }
}

function findPermission(role, element, module) {
  if (module) {
    let user = userStore?.activeUser ?? JSON.parse(localStorage.getItem("activeUser"));
    let userModules = user?.userModuleRole;
    let hasPermission = userModules?.find(x => x?.modules?.short_code == module);
    if (hasPermission == null && role != 'OWNER') {
      return false;
    } else {
      return true;
    }
  } else {
    switch (role) {
      case 'READONLY':
        switch (element) {
          case 'INV': return true;
          case 'PO': return true;
          case 'BILLS': return true;
          case 'PRODUCT': return false;
          case 'BANK': return true;
          case 'REPORT': return true;
          case 'ASSET': return false;
          case 'ACCOUNT': return false;
          case 'ACCOUNTING': return true;
          case 'CREATE': return false;
          case 'FILES': return true;
          case 'SETTINGS': return false;
          case 'SUBS': return false;
          default: return false;
        }
      case 'INVOICEONLY':
        switch (element) {
          case 'INV': return true;
          case 'PO': return true;
          case 'BILLS': return false;
          case 'PRODUCT': return false;
          case 'BANK': return false;
          case 'REPORT': return false;
          case 'ASSET': return false;
          case 'ACCOUNT': return false;
          case 'ACCOUNTING': return false;
          case 'FILES': return true;
          case 'SETTINGS': return false;
          case 'SUBS': return false;
          case 'CREATE': return true;
          default: return false;
        }
      case 'STANDARD':
        switch (element) {
          case 'INV': return true;
          case 'PO': return true;
          case 'BILLS': return true;
          case 'PRODUCT': return true;
          case 'BANK': return true;
          case 'REPORT': return true;
          case 'ASSET': return true;
          case 'ACCOUNT': return true;
          case 'ACCOUNTING': return true;
          case 'FILES': return true;
          case 'SETTINGS': return true;
          case 'SUBS': return false;
          case 'CREATE': return true;
          default: return false;
        }
      case 'ADVISOR':
        switch (element) {
          case 'INV': return true;
          case 'PO': return true;
          case 'BILLS': return true;
          case 'PRODUCT': return true;
          case 'BANK': return true;
          case 'REPORT': return true;
          case 'ASSET': return true;
          case 'ACCOUNT': return true;
          case 'ACCOUNTING': return true;
          case 'FILES': return true;
          case 'SETTINGS': return true;
          case 'SUBS': return false;
          case 'CREATE': return true;
          default: return false;
        }
      case 'OWNER':
        switch (element) {
          case 'INV': return true;
          case 'PO': return true;
          case 'BILLS': return true;
          case 'PRODUCT': return true;
          case 'BANK': return true;
          case 'REPORT': return true;
          case 'ASSET': return true;
          case 'ACCOUNT': return true;
          case 'ACCOUNTING': return true;
          case 'FILES': return true;
          case 'SETTINGS': return true;
          case 'SUBS': return true;
          case 'CREATE': return true;
          default: return true;
        }
    }
  }
}



export { Access, findPermission };


